import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

export const Features = () => (
    <div className="p-8 mt-8">
        <h1 className="text-center text-4xl font-bold">
            Farmote is a smart, innovative pasture monitoring system that combines satellite-based multispectral technology with accurate on-ground Motes to take the guesswork out of pasture and grazing management.
        </h1>
        <div className="grid md:grid-cols-6 sm:grid-cols-4 xs:grid-cols-1 gap-16 mt-16">
            <div className="flex flex-col">
                <StaticImage src="../images/solar-powered.png" alt="solar" placeholder="blurred" layout="fixed" width={80} className="self-center"/>
                <h4 className="text-center text-md font-bold">Solar Powered</h4>
            </div>
            <div className="flex flex-col">
                <StaticImage src="../images/robust.png" alt="solar" placeholder="blurred" layout="fixed" width={80} className="self-center"/>
                <h4 className="text-center text-md">Robust</h4>
            </div>
            <div className="flex flex-col">
                <StaticImage src="../images/install.png" alt="solar" placeholder="blurred" layout="fixed" width={80} className="self-center"/>
                <h4 className="text-center text-md">Easy To Install</h4>
            </div>
                <div className="flex flex-col">
                <StaticImage src="../images/accurate.png" alt="solar" placeholder="blurred" layout="fixed" width={80} className="self-center"/>
                <h4 className="text-center text-md">Accurate Monitoring</h4>
            </div>
            <div className="flex flex-col">
                <StaticImage src="../images/tractor.png" alt="solar" placeholder="blurred" layout="fixed" width={80} className="self-center"/>
                <h4 className="text-center text-md">Designed For Farm Use</h4>
            </div>
            <div className="flex flex-col">
                <StaticImage src="../images/download.png" alt="solar" placeholder="blurred" layout="fixed" width={80} className="self-center"/>
                <h4 className="text-center text-md">Automatic Data Transfer</h4>
            </div>
        </div>
    </div>
)