import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { Features } from '../components/features';
import { TopThree } from '../components/topThree';
import { Profits } from '../components/profits';
import { Media } from '../components/media';
import { Team } from '../components/team';
import { Contact } from '../components/contact';
import Footer from '../components/footer';

const App = () => {
    return (
        <>
            <Layout>
                <Seo title="Pasture Management For Dairy Farms" />
                <Features />
                <TopThree />
                <Profits />
                <Media />
                <Team />
            </Layout>
            <Contact />
            <Footer />
        </>
    );
};

export default App;
