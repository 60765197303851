import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export const Team = () => (
    <section id="team" className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-32 p-8">
        <div className="col-span-1 md:col-span-2 text-6xl text-pink-500 text-center mb-16">
            <strong>Our Team</strong>
        </div>
        <div className="flex flex-col">
            <StaticImage src="../images/richard.jpg" alt="Digital map" width={300} layout="fixed" />
            <div className="text-xl font-bold mt-4">Richard Barton</div>
            <div className="text-sm font-bold mt-4">Founder & Managing Director</div>
            <p className="mt-2">
                Richard is responsible for the physical form and function of our products. An engineer turned
                entrepreneur, he worked in the pharmaceutical machinery sector in the UK before returning to New Zealand
                to setup Farmote Systems. Richard has over fifteen years of experience in designing, prototyping and
                testing a wide range of healthcare, consumer, and industrial products as well as being the Engineering
                Director at ChargePoint Technology for 7 years
            </p>
        </div>
        <div className="flex flex-col">
            <StaticImage src="../images/ram.jpg" alt="Digital map" width={300} layout="fixed" />
            <div className="text-xl font-bold mt-4">Ram Manthry</div>
            <div className="text-sm font-bold mt-4">Web Application Technical Lead</div>
            <p className="mt-2">
                Ram is a veteran developer and is in charge of the web application and database. Ram has over ten years
                of experience in programming and application development working at companies such as TradeMe and Xero
                where he was responsible for leading teams of developers to deliver cutting edge projects. Ram is a
                graduate of Auckland University of Technology and holds a Postgraduate Diploma in Computer and
                Information Systems.
            </p>
        </div>
    </section>
);
