import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

export const Media = () => (
    <section id="media" className="mt-32 p-8">
        <div className="text-center text-6xl text-pink-500 mb-16"><strong>Media</strong></div>
        <div className="flex flex-row flex-wrap gap-8 items-center">
            <a href='https://issuu.com/ashguardian/docs/guardian_farming_25-sept-2021_52_pages_for_web_/14' target='_blank'>
                <StaticImage src="../images/media/ashburton-guardian.png" style={{ maxWidth: 250, maxHeight: 50 }} alt="Ashburton Guardian" />
            </a>
            <a href='https://www.odt.co.nz/rural-life/rural-people/pasture-gauge-without-guesswork' target='_blank'>
                <StaticImage src="../images/media/central-rural-life.png" style={{ maxWidth: 250, maxHeight: 50 }} alt="Central Rural Life"/>
            </a>
            <a href='https://www.nzherald.co.nz/the-country/news/christchurch-design-engineers-pasture-growth-system-outsmarts-bored-cows/7ALZV6N5N5ZXAMTXFI6ESQW4HE/' target="_blank">
                <StaticImage src="../images/media/NZ Herald.png" style={{ maxWidth: 250, maxHeight: 50 }} alt="NZ Herald"/>
            </a>
            <a href='https://www.odt.co.nz/rural-life/rural-people/pasture-gauge-without-guesswork' target='_blank'>
                <StaticImage src="../images/media/ODT.png" style={{ maxWidth: 100, maxHeight: 50 }} alt="ODT"/>
            </a>
            <a href='https://www.stuff.co.nz/business/farming/101086564/two-canterbury-startup-companies-get-legup-from-sprout-agritech' target="_blank">
                <StaticImage src="../images/media/Stuff.png" style={{ maxWidth: 100, maxHeight: 41 }} alt="Stuff"/>
            </a>
            <a href='https://www.ruralnewsgroup.co.nz/rural-news/rural-management/goodbye-to-plate-meter' target='_blank'>
                <StaticImage src="../images/media/rural-news.png" style={{ maxWidth: 250, maxHeight: 50 }} alt="Rural News"/>
            </a>
            <StaticImage src="../images/media/gold_am.png" style={{ maxWidth: 250, maxHeight: 90 }} className='bg-contain' alt="Gold AM"/> 
        </div>
    </section>
)