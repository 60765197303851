import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

export const Profits = () => (
    <section id="how-it-works" className="grid grid-cols-1 md:grid-cols-2 gap-16 mt-32 p-8">
        <div className="md:col-span-2 text-6xl text-pink-500"><strong>Profit from farm-wide technology</strong></div>
        <div className="md:col-span-2">Farmote System’s revolutionary combination of data from satellites in space and Motes on the ground, provides precise daily pasture measurements and invaluable insights through a mobile-friendly web platform. It cross-references pasture growth data collected daily, using accurate remote monitoring devices in your paddocks, with multispectral images taken by satellites. Critical, up-to-date information is then uploaded for you to access online, wherever you are, at any time. This all adds up to increased efficiency and productivity through good decision-making at the right time, leading to the ultimate goal of greater profitability.</div>
        <div className="flex flex-col flex-row">
            <StaticImage src="../images/digital-map.jpeg" alt="Digital map"/>
            <div className="text-xl font-bold mt-4 ml-4">1: Digital Map</div>
            <p className="ml-4">The Farmote System begins with a precise digital map of your entire farm</p>
        </div>
        <div className="flex flex-col">
            <StaticImage src="../images/boundaries.jpeg" alt="Digital map"/>
            <div className="text-xl font-bold mt-4 ml-4">2: Paddock Boundaries</div>
            <p className="ml-4">Boundaries of all paddocks are drawn, and Motes are installed at optimal locations across the farm</p>
        </div>
        <div className="flex flex-col">
            <StaticImage src="../images/imagery.jpeg" alt="Digital map"/>
            <div className="text-xl font-bold mt-4 ml-4">3: Multispectral Imagery</div>
            <p className="ml-4">Every day, data is collected from the Motes and combined with the latest multispectral satellite images</p>
        </div>
        <div className="flex flex-col">
            <StaticImage src="../images/pasture-data.jpeg" alt="Digital map"/>
            <div className="text-xl font-bold mt-4 ml-4">4: Critical Pasture Data</div>
            <p className="ml-4">You receive precise daily pasture mass measurements for each paddock through the mobile-friendly website</p>
        </div> 
    </section>
)