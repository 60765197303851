import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

export const TopThree = () => (
    <div className="mt-16">
        <ul className="text-6xl md:text-7xl font-bold">
            <li className="text-gray-800">Integrated technology.</li>
            <li className="text-gray-300">Less effort.</li>
            <li className="text-pink-500">Increased profits.</li>
        </ul>
        <div className='hidden md:block'>
            <div className="grid grid-cols-2 gap-8 mt-32 p-8 gap-y-32">
                <div>
                    <h3 className="text-5xl text-gray-800"><strong>Integrated technology.</strong></h3>
                    <h4 className="text-md mt-4"><strong>How the Farmote System works</strong></h4>
                    <p className="mt-4">The Farmote System works by collecting data from remote monitoring devices called ‘Motes’ installed at selected locations throughout your paddocks. The sensors contained within the Motes capture pasture measurements and soil conditions that are cross-referenced with multispectral images taken by satellites, and then uploaded to our website. By harnessing the latest technology in remote monitoring, you can make the right decisions that will boost your farm’s performance.</p>
                </div>
                <StaticImage src="../images/ipad-with-farmer.jpg" alt="technology"/>
                <StaticImage src="../images/richard-pressing-mote-into-ground.jpg" alt="technology"/>
                <div>
                    <h3 className="text-5xl text-gray-300"><strong>Less effort.</strong></h3>
                    <h4 className="text-md mt-4"><strong>Saving your time</strong></h4>
                    <p className="mt-4">Efficiently running a farm takes a lot of time and effort. The Farmote System is easy to install and set up, and will save you precious time better spent running other aspects of your farm. Up-to-date pasture data is delivered directly to your computer and smart-phone through our mobile-friendly website, saving you time and effort. With accurate daily data, you can quickly make informed decisions to improve your pasture performance.</p>
                </div>
                <div>
                    <h3 className="text-5xl text-pink-500"><strong>Increased profits.</strong></h3>
                    <h4 className="text-md mt-4"><strong>How you benefit</strong></h4>
                    <p className="mt-4">Farmote Systems helps you increase productivity and drive profitability by making your farm more efficient and effective. Making the correct decisions at the right time is critical – the Farmote System provides precise pasture data, allowing you to make the best decisions possible for your farm.</p>
                </div>
                <StaticImage src="../images/cow-sniffing-mote.jpg" alt="technology"/>
            </div>
        </div>
        <div className='block md:hidden'>
            <div className="grid gid-cols-1 gap-8 mt-32 p-8 gap-y-32">
                <div>
                    <h3 className="text-5xl text-gray-800"><strong>Integrated technology.</strong></h3>
                    <h4 className="text-md mt-4"><strong>How the Farmote System works</strong></h4>
                    <p className="mt-4">The Farmote System works by collecting data from remote monitoring devices called ‘Motes’ installed at selected locations throughout your paddocks. The sensors contained within the Motes capture pasture measurements and soil conditions that are cross-referenced with multispectral images taken by satellites, and then uploaded to our website. By harnessing the latest technology in remote monitoring, you can make the right decisions that will boost your farm’s performance.</p>
                </div>
                <StaticImage src="../images/ipad-with-farmer.jpg" alt="technology"/>
                <div>
                    <h3 className="text-5xl text-gray-300"><strong>Less effort.</strong></h3>
                    <h4 className="text-md mt-4"><strong>Saving your time</strong></h4>
                    <p className="mt-4">Efficiently running a farm takes a lot of time and effort. The Farmote System is easy to install and set up, and will save you precious time better spent running other aspects of your farm. Up-to-date pasture data is delivered directly to your computer and smart-phone through our mobile-friendly website, saving you time and effort. With accurate daily data, you can quickly make informed decisions to improve your pasture performance.</p>
                </div>
                <StaticImage src="../images/richard-pressing-mote-into-ground.jpg" alt="technology"/>
                <div>
                    <h3 className="text-5xl text-pink-500"><strong>Increased profits.</strong></h3>
                    <h4 className="text-md mt-4"><strong>How you benefit</strong></h4>
                    <p className="mt-4">Farmote Systems helps you increase productivity and drive profitability by making your farm more efficient and effective. Making the correct decisions at the right time is critical – the Farmote System provides precise pasture data, allowing you to make the best decisions possible for your farm.</p>
                </div>
                <StaticImage src="../images/cow-sniffing-mote.jpg" alt="technology"/>
            </div>
        </div>
    </div>
)